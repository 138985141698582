import React from 'react'

import { Tckr1 } from './Tckr1'
import { Tckr2 } from './Tckr2'

import { useGetSeriesData } from './hooks/useGetSeriesData';

import './fonts/SubwayTickerGrid.ttf'


export const TckrShell = () => {

    const {data: seriesdata} = useGetSeriesData();

    // console.log(seriesdata)

    return( seriesdata &&
        <Tckr2 seriesdata={seriesdata} />
    )
}