import React, {useState} from 'react'

import { useNavigate } from "react-router-dom";

import './styles.css'
import './App.css'
import './fonts/SubwayTickerGrid.ttf'

const gold = '#FFD700'
const white = '#FFFFFF'
const green = '#00FF00'

export const Home = () => {

    const navigate = useNavigate()

    const [goldLetters, setGoldLetters] = useState([])

    const unlocked = goldLetters.includes("S") && 
                       !goldLetters.includes("Y") &&
                       goldLetters.includes("M") && 
                       !goldLetters.includes("B") && 
                       goldLetters.includes("L") && 
                       !goldLetters.includes("SS") && 
                       goldLetters.includes(".") && 
                       !goldLetters.includes("a") && 
                       goldLetters.includes("i")

    const handleClickLetter = (e, dupe_S, unlocked) => {

        if (unlocked) {
            navigate("/tckr")
            return
        }

        const letter = dupe_S ? "SS" : e.target.innerHTML

        if (goldLetters.includes(letter)) {
            const newGolds = goldLetters.filter(g => g !== letter)
            setGoldLetters(newGolds)
        }
        else {
            const newGolds = [...goldLetters, letter]
            setGoldLetters(newGolds)
        }
    }

    // console.log(unlockedL1)
    // console.log(goldLetters)

    return(
        <div 
        
        style={{
                background: `#000000`, 
                maxHeight: '100vh', 
                height: '100vh',
                width: '100vw',
                display: 'flex', alignItems: "center", justifyContent: "center",
                color: '#FFFFFF',
                fontFamily: 'Ticker',
                fontSize: '2rem',
                // overscrollBehavior: 'none'
                }}>
                
            <div style={{cursor: "default", color: goldLetters.includes('S') ? gold : white}} onClick={handleClickLetter}>S</div>
            <div style={{cursor: "default", color: goldLetters.includes('Y') ? gold : white}} onClick={handleClickLetter}>Y</div>
            <div style={{cursor: "default", color: goldLetters.includes('M') ? gold : white}} onClick={handleClickLetter}>M</div>
            <div style={{cursor: "default", color: goldLetters.includes('B') ? gold : white}} onClick={handleClickLetter}>B</div>
            <div style={{cursor: "default", color: goldLetters.includes('L') ? gold : white}} onClick={handleClickLetter}>L</div>
            <div style={{cursor: "default", color: goldLetters.includes('SS') ? gold : white}} onClick={(e) => handleClickLetter(e, true)}>S</div>
            <div style={{cursor: "default", color: unlocked ? green : goldLetters.includes('.') ? gold : white}} onClick={(e) => handleClickLetter(e, false, unlocked && true)}>.</div>
            <div style={{cursor: "default", color: goldLetters.includes('a') ? gold : white}} onClick={handleClickLetter}>a</div>
            <div style={{cursor: "default", color: goldLetters.includes('i') ? gold : white}} onClick={handleClickLetter}>i</div>
            
        </div>
    )
}