import {api} from '../index';
import { useQuery } from "@tanstack/react-query";

const getSeriesData = () => api
                        .get('/seriesdata')
                        .then(response => {
                        const data = response.data;
                        // console.log(data);
                        return data;
                        });

export const useGetSeriesData = () => {

  async function getAllSeriesData() {
    const data = await getSeriesData();
    // console.log('data: ', data);
    return data;
  }
    
  return useQuery({
    queryKey: ['seriesdata'], 
    queryFn: getAllSeriesData,
    staleTime: (1 * (60 * (60 * 1000))), // 1 hr 
    cacheTime: (5 * (60 * (60 * 1000))), // 5 hr
  });

}