import './App.css';

import { Home } from './Home';
import { TckrShell } from './TckrShell';

import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";

function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
      <Route path='/' element={<Home />} />
      <Route path='tckr' element={<TckrShell />} />
      </>
    )
  );

  return (
    <div style={{overscrollBehavior: 'none'}} className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
